<template>
  <component :is="layout">
    <span v-if="message">
      <p class="text-center alert-success">{{ message }}</p>
    </span>
    <div class="card shadow p-2 m-2 text-center">
      <div class="bg-primary py-2">
        <h3>Daily Game</h3>
      </div>
      <table class="table border table-responsive">
        <thead>
          <th>Id</th>
          <th>Name</th>
          <th>Time</th>
          <th>Result</th>
          <th>Action</th>
        </thead>
        <tbody v-if="dailyGame" class="align-items-center">
          <td>{{ dailyGame.id }}</td>
          <td>{{ dailyGame.name }}</td>
          <td>{{ dailyGame.time }}</td>
          <td>{{ dailyGame.result }}</td>
          <td>
            <button
              @click="showDailyEditModal = true"
              class="btn m-1 bg-primary text-white"
            >
              Edit
            </button>
          </td>
        </tbody>
      </table>
    </div>

    <div class="card shadow p-2 m-2 text-center">
      <div class="d-flex p-2 justify-content-between">
        <h3 class="text-dark">Daily Game Panel</h3>
        <button @click="showAddPanel()" class="btn btn-warning bg-warning text-white">
          Add Panel
        </button>
      </div>
      <table class="table border table-responsive">
        <thead>
          <th>Date</th>
          <th>Action</th>
        </thead>
        <tbody v-if="dailyGamePanel" class="align-items-center">
          <tr v-for="panelRecord in dailyGamePanel.data" :key="panelRecord.id">
            <td>
              <div>
                <span>{{ changeDateFormat(panelRecord.from_date) }}</span>
                <br />
                <span>to</span>
                <br />
                <span>{{ changeDateFormat(panelRecord.to_date) }}</span>
              </div>
            </td>

            <td>
              <button
                @click="showEditPanel(panelRecord)"
                class="btn m-1 bg-primary btn-primary btn-sm"
              >
                Edit
              </button>
              <button
                @click="deletePanel(panelRecord.id)"
                class="btn m-1 bg-danger btn-danger btn-sm"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination
        v-if="dailyGamePanel"
        :per-page="10"
        v-model="page"
        :records="dailyGamePanel.total"
        @paginate="fetchDailyGamePanel"
      />
    </div>
    <modal :showModal="showDailyEditModal">
      <template v-slot:header>
        <h3 class="text-center">{{ dailyGame.name }}</h3>
      </template>
      <template v-slot:body>
        <div class="form-group">
          <label for="name">Name</label>
          <input
            placeholder="name"
            class="form-control"
            v-model="dailyGame.name"
          />
        </div>
        <div class="form-group">
          <label for="time">Time</label>
          <input
            placeholder="time"
            class="form-control"
            v-model="dailyGame.time"
          />
        </div>
        <div class="form-group">
          <label for="result">Result</label>
          <input
            placeholder="result"
            class="form-control"
            v-model="dailyGame.result"
          />
        </div>
      </template>
      <template v-slot:footer>
        <button
          @click="updateDailyGame()"
          class="btn btn-success bg-success modal-default-button"
        >
          Update
        </button>
        <button
          class="btn btn-danger bg-danger modal-default-button"
          @click="showDailyEditModal = false"
        >
          close
        </button>
      </template>
    </modal>
    <modal :showModal="showAddModal">
      <template v-slot:header>
        <h3 class="text-center">{{ dailyGame.name }}</h3>
      </template>
      <template v-slot:body>
        <span v-if="errors.length">
          <b>Please correct the following error(s):</b>
          <p class="alert-danger" v-for="error in errors" :key="error">
            {{ error }}
          </p>
        </span>
        <label for="">Start Date</label>
        <date-picker v-model="from_date" format="DD-MM-YYYY"></date-picker>
        <label for="">End Date</label>
        <date-picker v-model="to_date" format="DD-MM-YYYY"></date-picker>
      </template>
      <template v-slot:footer>
        <button
          @click="storeAddPanel()"
          class="btn bg-success btn-success modal-default-button"
        >
          Add Panel
        </button>
        <button
          class="btn bg-danger btn-danger modal-default-button"
          @click="showAddModal = false"
        >
          close
        </button>
      </template>
    </modal>

    <modal :showModal="showEditModal">
      <template v-slot:header>
        <h3 class="text-center">{{ dailyGame.name }}</h3>
      </template>
      <template v-slot:body>
        <div class="d-flex justify-content-around">
          <div class="form-group">
            <label for="">Monday</label>
            <input
              size="10"
              type="text"
              v-model="panelRecord.monday"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="">Tuesday</label>
            <input
              size="10"
              type="text"
              v-model="panelRecord.tuesday"
              class="form-control"
            />
          </div>
        </div>
        <div class="d-flex justify-content-around">
          <div class="form-group">
            <label for="">Wednesday</label>
            <input
              size="10"
              type="text"
              v-model="panelRecord.wednesday"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="">Thursday</label>
            <input
              size="10"
              type="text"
              v-model="panelRecord.thursday"
              class="form-control"
            />
          </div>
        </div>
        <div class="d-flex justify-content-around">
          <div class="form-group">
            <label for="">Friday</label>
            <input
              size="10"
              type="text"
              v-model="panelRecord.friday"
              class="form-control"
            />
          </div>
          <div v-if="'saturday' in panelRecord" class="form-group">
            <label for="">Saturday</label>
            <input
              size="10"
              type="text"
              v-model="panelRecord.saturday"
              class="form-control"
            />
          </div>
        </div>
        <div v-if="'sunday' in panelRecord" class="form-group">
          <label for="">Sunday</label>
          <input
            type="text"
            v-model="panelRecord.sunday"
            class="form-control"
          />
        </div>
      </template>
      <template v-slot:footer>
        <button
          @click="updatePanel(panelRecord.id)"
          class="btn bg-success btn-success bg-success modal-default-button"
        >
          Update Panel
        </button>
        <button
          class="btn bg-danger btn-danger bg-danger modal-default-button"
          @click="showEditModal = false"
        >
          close
        </button>
      </template>
    </modal>
  </component>
</template>

<script>
import Modal from "../Modal.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Pagination from "vue-pagination-2";
import axiosConfig from '../../axiosConfig';
import axiosObject from '../../axiosHandler';

export default {
  components: {
    Modal,
    DatePicker,
    Pagination,
  },
 
  data() {
    return {
      dailyGame: Object,
      dailyGamePanel: null,
      page: 1,
      days: 7,
      showEditModal: false,
      showAddModal: false,
      showDailyEditModal: false,
      panelRecord: Object,
      errors: [],
      message: null,
      from_date: null,
      to_date: null,
      creating:false
    };
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  created() {
    this.fetchDailyGame();
  },
  methods: {
    fetchDailyGame() {
      axiosObject.get("/api/client/daily_game",axiosConfig)
        .then(
          function (response) {
            this.dailyGame = response.data.daily_games;
            this.days = response.data.daily_games.days;
            this.fetchDailyGamePanel();
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
    },
    updateDailyGame() {
      axiosObject.post("/api/client/daily_game/", this.dailyGame,axiosConfig)
        .then(
          function (response) {
            this.fetchDailyGame();
            this.showDailyEditModal = false;
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
    },

    fetchDailyGamePanel(page = 1) {
      this.page = page;
      axiosObject.get("/api/client/day" + this.days + "panel?page=" + this.page,axiosConfig)
        .then(
          function (response) {
            this.dailyGamePanel = response.data.panel;
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
    },
    changeDateFormat(date) {
      return new Date(date).toLocaleDateString("en-IN");
    },
    recordObject(data) {
      if (!data) return;
      const value = new String(data);
      if (!value[9]) return;
      const comboValue = parseInt(value[4]) - parseInt(value[5]);
      if (comboValue == 5 || comboValue == 0) {
        return (
          '<div  class="d-flex border align-items-center justify-content-around">' +
          "<div>" +
          value[0] +
          "<br>" +
          value[1] +
          "<br>" +
          value[2] +
          "</div>" +
          '<div><h5 class="redword">' +
          value[4] +
          value[5] +
          "</h5></div>" +
          "<div>" +
          value[7] +
          "<br>" +
          value[8] +
          "<br>" +
          value[9] +
          "</div>" +
          "</div>"
        );
      }

      return (
        '<div  class="d-flex border align-items-center justify-content-around">' +
        "<div>" +
        value[0] +
        "<br>" +
        value[1] +
        "<br>" +
        value[2] +
        "</div>" +
        "<div><h5>" +
        value[4] +
        value[5] +
        "</h5></div>" +
        "<div>" +
        value[7] +
        "<br>" +
        value[8] +
        "<br>" +
        value[9] +
        "</div>" +
        "</div>"
      );
    },
    showAddPanel() {
      this.showAddModal = true;
    },

    storeAddPanel() {
      this.errors = [];
      this.panelRecord = new Object();
      this.panelRecord.from_date = this.from_date;
      this.panelRecord.to_date = this.to_date;
      this.panelRecord.id = this.dailyGame.id;
      if (!this.panelRecord.from_date || !this.panelRecord.to_date) {
        this.errors.push("From and To Dates are Required.");
        return;
      }

      if(this.creating == false)
      {
        this.creating=true
        axiosObject.post("/api/client/day" + this.days + "panel", this.panelRecord,axiosConfig)
        .then(
          function (response) {
            this.showAddModal = false;
            this.message = response.data.message;
            this.fetchDailyGamePanel(this.page);
            this.creating = false
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
          this.creating = false
        }.bind(this));
      }
    },

    showEditPanel(panelRecord) {
      this.showEditModal = true;
      this.panelRecord = panelRecord;
    },

    updatePanel(id) {
      axiosObject.patch("/api/client/day" + this.days + "panel/" + id, this.panelRecord,axiosConfig)
        .then(
          function (response) {
            this.showEditModal = false;
            this.fetchDailyGamePanel(this.page);
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
    },
    deletePanel(id) {
      axiosObject.delete("/api/client/day" + this.days + "panel/" + id,axiosConfig)
        .then(
          function (response) {
            this.fetchDailyGamePanel(this.page);
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
    },
  },
};
</script>

<style>
.redword {
  color: red;
}
</style>
